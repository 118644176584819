import { Module } from "vuex";
import { UserState } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

export const USER: Module<UserState, any> = {
  namespaced: true,
  state: {
    userInfo: {
      username: "",
      name: "",
      lastname: "",
      email: "",
    },
    token: "",
  },
  mutations,
  getters,
  actions,
};
