import { MutationTree } from "vuex";
import { UserState } from "./types";

export enum UserMutations {
  SET_USER = "SET_USER",
  SET_TOKEN = "SET_TOKEN",
}

export const mutations: MutationTree<UserState> = {
  [UserMutations.SET_USER](state, payload) {
    state.userInfo = payload;
  },
  [UserMutations.SET_TOKEN](state, payload) {
    state.token = payload;
  },
};
