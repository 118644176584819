import apiAdmin from "@/api/apiAdmin";
import HmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { UserState, login, signup } from "./types";
import { UserMutations } from "./mutations";
import store, { AppMutations } from "@/store";
import { jwtDecode } from "jwt-decode";

export const actions: ActionTree<UserState, RootState> = {
  // async encrypt(arg1: any) {
  //   console.log("part1");
  //   return hash;
  // },
  async signin({ commit }, payload: login) {
    const hash = Base64.stringify(HmacSHA512(payload.hash, payload.username));
    const req: login = {
      username: payload.username,
      hash: hash,
    };
    const base64 = { data: btoa(JSON.stringify(req)) };
    const result = await (await apiAdmin.signin(base64)).data;
    if (result.token) {
      commit(`${UserMutations.SET_TOKEN}`, result.token);
      commit(`${UserMutations.SET_USER}`, result.data.result.user);
      localStorage.userInfo = JSON.stringify(store.getters["USER/getUser"]);
      localStorage.token = result.token;
    }
    return result;
  },
  async changePass({ commit }, secure: any) {
    const hash = Base64.stringify(
      HmacSHA512(
        secure.currentPassword,
        JSON.parse(localStorage.userInfo).username
      )
    );
    const hash1 = Base64.stringify(
      HmacSHA512(secure.newPassword, JSON.parse(localStorage.userInfo).username)
    );
    const req: any = {
      username: JSON.parse(localStorage.userInfo).username,
      hash: hash,
      hash1: hash1,
    };
    const base64 = { data: btoa(JSON.stringify(req)) };
    const result = await (await apiAdmin.hash(base64)).data;
    return result;
  },
  async signup({ commit }, payload: signup) {
    const hash = Base64.stringify(HmacSHA512(payload.hash, payload.userName));
    // const hash = await dispatch("encrypt", payload);
    const req: signup = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      userName: payload.userName,
      email: payload.email,
      hash: hash,
      code: payload.code,
      birthdayDate: payload.birthdayDate,
      terms: payload.terms,
    };
    const result = await apiAdmin.signup(req);
    return result;
  },
  async getUserInfo(_, username: string) {
    const result = await (await apiAdmin.getUserInfo(username)).data;
    localStorage.userInfo = JSON.stringify(result);
    return result;
  },
  async getProfile(_) {
    const result = await (await apiAdmin.getProfile()).data;
    //localStorage.userInfo = JSON.stringify(result);
    return result;
  },
  async LogIn({ commit }, code: string) {
    const baseUrl = window.location.pathname.split("/").slice(0, -1).join("/");
    window.history.pushState({}, "", baseUrl);
    try {
      const decodedToken: any = jwtDecode(code);
      const user = {
        idUser: decodedToken.iduser,
        username: decodedToken.username,
        name: decodedToken.firstname,
        lastname: decodedToken.lastname,
        email: decodedToken.email,
        picture: decodedToken.picture,
        raf: decodedToken.raf,
      };
      commit(`${UserMutations.SET_TOKEN}`, code);
      commit(`${UserMutations.SET_USER}`, user);
      localStorage.userInfo = JSON.stringify(store.getters["USER/getUser"]);
      localStorage.token = code;
      if (localStorage.rafCode) {
        const result = await (
          await apiAdmin.updateRafParent(localStorage.rafCode)
        ).data;
      }
    } catch (error) {
      console.error("Error al decodificar el token:", error);
    }
  },
};
