import { MutationTree } from "vuex";
import { HomeState } from "./types";

export enum HomeMutations {
  SET_PROYECTS = "SET_PROYECTS",
  SET_PROYECT_ID = "SET_PROYECT_ID",
}

export const mutations: MutationTree<HomeState> = {
  [HomeMutations.SET_PROYECTS](state, payload) {
    state.proyects = payload;
  },
  [HomeMutations.SET_PROYECT_ID](state, payload) {
    state.proyect_id = payload;
  },
};
