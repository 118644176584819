import apiAdmin from "@/api/apiAdmin";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { UserState } from "./types";

export const actions: ActionTree<UserState, RootState> = {
  async getUserInfo({ commit }, username: string) {
    const result = await apiAdmin.getUserInfo(username);
    return result;
  },
};
