import apiTrensaction from "@/api/apiTransaction";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { UserState } from "./types";
export const actions: ActionTree<UserState, RootState> = {
  // async encrypt(arg1: any) {
  //   console.log("part1");
  //   return hash;
  // },
  async postTransaction(_, info: any) {
    const result = await (await apiTrensaction.postTransaction(info)).data;
    return result;
  },
};
