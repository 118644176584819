import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast, { PluginOptions } from "vue-toastification";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "vue-toastification/dist/index.css";
import "@/assets/main.scss";
import GoogleSignInPlugin from "vue3-google-signin";

const options: PluginOptions = {
  // You can set your default options here
};

library.add(fas, fab);

createApp(App)
  .use(Toast, options)
  .use(store)
  .use(GoogleSignInPlugin, {
    clientId:
      "959655338224-pgfc8ke8aesn81un1snsiesqg1nttdiv.apps.googleusercontent.com",
  })
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
