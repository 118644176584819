import apiAdmin from "@/api/apiAdmin";
import HmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { HomeState } from "./types";
import { HomeMutations } from "./mutations";
import apiProyects from "@/api/apiProyects";

export const actions: ActionTree<HomeState, RootState> = {
  // async encrypt(arg1: any) {
  //   console.log("part1");
  //   return hash;
  // },
  async getInfoProyects({ commit }) {
    const result = await (await apiProyects.getInfoProyects()).data;
    commit(`${HomeMutations.SET_PROYECTS}`, result);
    return result;
  },
  async getInfoProyectsByID({ commit }, idLand: number) {
    const result = await (await apiProyects.getInfoProyectByID(idLand)).data;
    commit(`${HomeMutations.SET_PROYECTS}`, result.token);
    return result;
  },
};
