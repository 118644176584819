import { createStore } from "vuex";
import { USER } from "../modules/user/store";
import { USERINFO } from "../modules/profile/store";
import { HOME } from "../modules/dashboard/store";
import { INVESTING } from "../modules/investing/store";

export enum AppMutations {
  SET_ALLOWED = "SET_ALLOWED",
}

export default createStore({
  state: {
    initialized: false,
    allowed: false,
  },
  getters: {
    getAllowed(state) {
      return state.allowed;
    },
  },
  mutations: {
    [AppMutations.SET_ALLOWED](state, payload) {
      state.allowed = payload;
    },
  },
  actions: {},
  modules: {
    USER,
    USERINFO,
    HOME,
    INVESTING,
  },
});
