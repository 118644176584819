import { api } from "@/api/mytp";

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getInfoProyects() {
    return api.get("/api/home/proyects");
  },
  async getInfoProyectByID(idLand: number) {
    return api.get(`/api/home/proyects/${idLand}`);
  },
};
