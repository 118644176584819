import { UserMutations } from "@/modules/user/store/mutations";
import store, { AppMutations } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/crowdfunding/:user?",
    name: "CroudState",
    component: () => import("@/layout/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/dashboard.vue"),
        props: true,
      },
      {
        path: "investing",
        name: "Investing",
        component: () => import("@/modules/investing/views/home.vue"),
      },
      {
        path: "about-us",
        name: "AboutUs",
        component: () => import("@/modules/aboutUs/views/home.vue"),
      },
    ],
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/layout/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "news",
        component: () => import("@/modules/blog/views/home.vue"),
      },
      {
        path: "bogota-despojado",
        name: "noticia",
        component: () => import("@/modules/blog/components/Notice.vue"),
      },
      {
        path: "Medellin-Capital-Inversiones",
        name: "notice",
        component: () => import("@/modules/blog/components/Notice1.vue"),
      },
      {
        path: "incremento-en-arriendos",
        name: "notice2",
        component: () => import("@/modules/blog/components/Notice2.vue"),
      },
      {
        path: "inversiones-en-ladrillo",
        name: "notice3",
        component: () => import("@/modules/blog/components/Notice3.vue"),
      },
      {
        path: "plataformas-de-crowdfunding",
        name: "notice4",
        component: () => import("@/modules/blog/components/Notice4.vue"),
      },
      {
        path: "inflacion-con-un-bonyourt",
        name: "notice5",
        component: () => import("@/modules/blog/components/Notice5.vue"),
      },
    ],
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/modules/blog/views/home.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/modules/profile/views/home.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("@/modules/user/components/sign-in.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign-up",
    component: () => import("@/modules/user/components/sign-up.vue"),
    props: (route) => ({
      raf: route.query.raf || null,
    }),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { path: "/crowdfunding" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const EXEPTIONS = [
  "sign-in",
  "sign-up",
  "investing",
  "crowdfunding",
  "about-us",
  "blog",
];

router.beforeEach(async (to) => {
  store.commit(AppMutations.SET_ALLOWED, false);
  const accessToken = localStorage.token;
  if (accessToken === "undefined" || accessToken === undefined) {
    if (EXEPTIONS.indexOf(to.path.split("/")[1]) !== -1) {
      console.log("Acceso válido sin token");
    } else {
      router.push({ path: "sign-in" });
    }
  } else {
    store.commit(AppMutations.SET_ALLOWED, true);
    store.commit(`USER/${UserMutations.SET_TOKEN}`, localStorage.token);
  }
});

export default router;
