import { api } from "@/api/mytp";

export default {
  async getContract() {
    return api.get("cervezas/getContract");
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async signup(info: any) {
    return api.post("/api/user/signup", info);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async signin(info: any) {
    return api.post("/api/user/signin", info);
  },
  async hash(info: any) {
    return api.post("/api/user/hash", info);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getUserInfo(Username: string) {
    return api.get(`/api/user/${Username}`);
  },
  async getProfile() {
    return api.get(`/api/user/profile`);
  },
  async updateRafParent(code: string) {
    return api.get(`/api/user/profile/update/${code}`);
  },
  async LogIn(code: string) {
    return api.post("/api/auth1/token", code);
  },
};
