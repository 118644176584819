import { Module } from "vuex";
import { HomeState } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

export const HOME: Module<HomeState, any> = {
  namespaced: true,
  state: {
    proyects: [],
    proyect_id: [],
  },
  mutations,
  getters,
  actions,
};
