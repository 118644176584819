import { UserState } from "./types";

export const getters: any = {
  getToken(state: UserState) {
    return state.token;
  },
  getUser(state: UserState) {
    return state.userInfo;
  },
};
