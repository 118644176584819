import { Module } from "vuex";
import { UserState } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

export const USERINFO: Module<UserState, any> = {
  namespaced: true,
  mutations,
  getters,
  actions,
};
